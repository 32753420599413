import { useParams } from '@solidjs/router';

import PostList from '~/components/PostList';

function shiftMonth(year: number, month: number) {
  let shiftedMonth = month + 1;
  let shiftedYear = year;

  if (shiftedMonth < 1) {
    shiftedMonth = 12;
    shiftedYear--;
  }

  if (shiftedMonth > 12) {
    shiftedMonth = 1;
    shiftedYear++;
  }

  return `${shiftedYear.toString()}-${shiftedMonth.toString().padStart(2, '0')}`;
}

function formatYearMonthPair(year: string, month: string) {
  return `${year}-${month}`;
}

export default function Month() {
  const parameters = useParams();

  const nextMonth = () =>
    shiftMonth(
      Number.parseInt(parameters.year, 10),
      Number.parseInt(parameters.month, 10),
    );

  return (
    <PostList
      filter={{
        category: { slug: parameters.category },
        publishedAt_GTE: formatYearMonthPair(parameters.year, parameters.month),
        publishedAt_LT: nextMonth(),
      }}
    />
  );
}
